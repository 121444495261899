import Loadable from 'react-loadable';
import loading from '../components/Loading';

export const Home = Loadable({
    loader: () => import('./Home'),
    loading
});

export const Project = Loadable({
    loader: () => import('./Project'),
    loading
});

export const ProjectsByCategories = Loadable({
    loader: () => import('./ProjectsByCategories'),
    loading
});

export const User = Loadable({
    loader: () => import('./User'),
    loading
});

/*
 * We don't really need dynamic loading for the Auth pages since most
 * of the Auth component is already imported through global modal
 */
export { SignIn, SignUp, ResetPassword } from './Auth';

export const Submit = Loadable({
    loader: () => import('./Submit'),
    loading
});

export const Messages = Loadable({
    loader: () => import('./Messages'),
    loading
});

export const About = Loadable({
    loader: () => import('./About'),
    loading
});

export const Legal = Loadable({
    loader: () => import('./Legal'),
    loading
});

// export const Admin = Loadable({
//     loader: () => import('./Admin'),
//     loading
// });

export const NotFound = Loadable({
    loader: () => import('./NotFound'),
    loading
});
