import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Authenticator from '../../components/Authenticator';

const AuthPage = props => (
    <Container className="py-5">
        <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5} className="my-lg-5">
                <Authenticator {...props} />
            </Col>
        </Row>
    </Container>
);

export const SignIn = () => <AuthPage authState="signIn" />;
export const SignUp = () => <AuthPage authState="signUp" />;
export const ResetPassword = () => <AuthPage authState="forgotPassword" />;
export default AuthPage;
