import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Jumbotron, Container, Row, Col } from 'reactstrap';
import {
    faTwitter,
    faInstagram,
    faFacebook,
    faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import SocialLink from './SocialLink';
import styles from './Footer.module.scss';

const siteLinks = [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about' },
    { name: 'Terms', href: '/terms' },
    { name: 'FAQ', href: '/faq' },
    { name: 'Privacy', href: '/privacy' }
];

const socialLinks = [
    {
        name: 'Twitter',
        href: process.env.REACT_APP_TWITTER_URL,
        icon: faTwitter
    },
    {
        name: 'Instagram',
        href: process.env.REACT_APP_INSTAGRAM_URL,
        icon: faInstagram
    },
    {
        name: 'Facebook',
        href: process.env.REACT_APP_FACEBOOK_URL,
        icon: faFacebook
    },
    {
        name: 'LinkedIn',
        href: process.env.REACT_APP_LINKEDIN_URL,
        icon: faLinkedin
    }
];

const Spacer = () => (
    <span className={classNames(styles.spacer, 'mx-2')}>&middot;</span>
);

const Footer = () => (
    <Jumbotron
        fluid
        className={classNames('mb-0', 'bg-light', 'text-center', 'text-muted')}
    >
        <Container>
            <Row className="align-items-center">
                <Col
                    xs={12}
                    md={6}
                    className={classNames(
                        'mb-3',
                        'mb-md-0',
                        'text-center',
                        'text-md-left'
                    )}
                >
                    {socialLinks.map((link, i) => {
                        let margin = 'mx-2';
                        if (i === 0) margin = 'mr-2';
                        else if (i === socialLinks.length) margin = 'ml-2';
                        return (
                            <SocialLink
                                key={link.name}
                                className={margin}
                                {...link}
                            />
                        );
                    })}
                </Col>
                <Col
                    xs={12}
                    md={6}
                    className={classNames('text-center', 'text-md-right')}
                >
                    {siteLinks.map(({ name, href }, idx) => (
                        <span key={name}>
                            <Link to={href}>{name}</Link>
                            {idx + 1 !== siteLinks.length && <Spacer />}
                        </span>
                    ))}
                    <br />
                    &copy; {new Date().getFullYear()} Pallet.click LLC
                    <Spacer />
                    {process.env.NODE_ENV === 'development' ? (
                        <span className="text-danger">Development Mode</span>
                    ) : (
                        'All rights reserved'
                    )}
                </Col>
            </Row>
        </Container>
    </Jumbotron>
);

export default Footer;
