import React from 'react';
import { Auth, I18n, Logger } from 'aws-amplify';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import AuthPiece from './AuthPiece';

const logger = new Logger('VerifyContact');
export default class VerifyContact extends AuthPiece {
    constructor(props) {
        super(props);

        this.validAuthStates = ['verifyContact'];
        this.verify = this.verify.bind(this);
        this.submit = this.submit.bind(this);

        this.state = { verifyAttr: null };
    }

    async verify() {
        const { contact, checkedValue } = this.inputs;

        if (!contact) {
            this.error('Neither Email nor Phone Number selected');
            return;
        }

        try {
            const data = await Auth.verifyCurrentUserAttribute(checkedValue);
            logger.debug(data);
            this.setState({ verifyAttr: checkedValue });
        } catch (error) {
            this.error(error);
        }
    }

    async submit() {
        const { authData } = this.props;
        const attr = this.state.verifyAttr;
        const { code } = this.inputs;

        try {
            const data = await Auth.verifyCurrentUserAttributeSubmit(
                attr,
                code
            );
            logger.debug(data);
            this.changeState('signedIn', authData);
            this.setState({ verifyAttr: null });
        } catch (error) {
            this.error(error);
        }
    }

    verifyView() {
        const user = this.props.authData;
        if (!user) {
            logger.debug('No user to verify');
            return null;
        }
        const { unverified } = user;
        if (!unverified) {
            logger.debug('User is already verified');
            return null;
        }
        const { email, phone_number: phone } = unverified;
        const RadioGroup = (value, label) => (
            <FormGroup check>
                <Label check>
                    <Input
                        type="radio"
                        name="contact"
                        value={value}
                        className="mr-2"
                        onChange={this.handleInputChange}
                    />
                    {I18n.get(label)}
                </Label>
            </FormGroup>
        );
        return (
            <FormGroup tag="fieldset">
                {email && <RadioGroup value={email} label="Email" />}
                {phone && <RadioGroup value={phone} label="Phone" />}
            </FormGroup>
        );
    }

    submitView() {
        return (
            <FormGroup row>
                <Label for="code" xs={3}>
                    {I18n.get('Code')}
                </Label>
                <Col xs={9}>
                    <Input
                        type="number"
                        placeholder="000000"
                        name="code"
                        autoFocus
                        autoComplete="off"
                        required
                        onChange={this.handleInputChange}
                    />
                </Col>
            </FormGroup>
        );
    }

    showComponent() {
        const { authData } = this.props;
        const { verifyAttr } = this.state;

        return (
            <Form onSubmit={this[verifyAttr ? 'submit' : 'verify']}>
                <div className="mb-4">
                    {I18n.get(
                        'Please verify your contact information in case you lose access to your account.'
                    )}
                </div>
                {this.state.verifyAttr ? this.submitView() : this.verifyView()}
                <Row className="mt-4">
                    <Col xs="auto" />
                    <Col className="text-right d-flex align-items-center">
                        <Button
                            color="light"
                            size="sm"
                            className="mr-2"
                            onClick={() =>
                                this.changeState('signedIn', authData)
                            }
                        >
                            {I18n.get('Skip')}
                        </Button>
                        <Button type="submit" color="primary" size="lg">
                            {I18n.get(verifyAttr ? 'Submit' : 'Verify')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}
