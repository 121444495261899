import React from 'react';
import { Auth, I18n, Logger } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faSignIn } from '@fortawesome/pro-solid-svg-icons';
import AuthPiece from './AuthPiece';

const logger = new Logger('ConfirmSignUp');

export default class ConfirmSignUp extends AuthPiece {
    constructor(props) {
        super(props);

        this.validAuthStates = ['confirmSignUp'];
        this.confirm = this.confirm.bind(this);
        this.resend = this.resend.bind(this);
    }

    async confirm(event) {
        if (event) event.preventDefault();
        const username = this.usernameFromAuthData() || this.inputs.username;
        const { code } = this.inputs;
        try {
            await Auth.confirmSignUp(username, code);
            this.changeState('signedUp');
            // TODO: Remove the above line, refactor SignIn.js to Hooks, extract
            // and export the class method signIn as a function, and call it here.
        } catch (err) {
            this.error(err);
        }
    }

    async resend() {
        const username = this.usernameFromAuthData() || this.inputs.username;
        try {
            await Auth.resendSignUp(username);
            logger.debug(`Resent verification code to ${username}`);
        } catch (err) {
            this.error(err);
        }
    }

    showComponent() {
        const { onModalToggle } = this.props;
        const username = this.usernameFromAuthData();
        const SignInLinkContents = () => (
            <>
                <FontAwesomeIcon icon={faSignIn} className="mr-1" />
                {I18n.get('Back to Sign In')}
            </>
        );
        return (
            <Form onSubmit={this.confirm}>
                <div className="mb-4">
                    {I18n.get(
                        'We sent you a code. Please enter it here to verify your account.'
                    )}
                </div>
                <Input
                    type="hidden"
                    name="username"
                    id="username"
                    key="username"
                    value={username || ''}
                    onChange={this.handleInputChange}
                />
                <FormGroup row className="align-items-center">
                    <Label for="password" xs={2}>
                        {I18n.get('Code')}
                    </Label>
                    <Col xs={10}>
                        <Input
                            type="text"
                            name="code"
                            id="code"
                            key="code"
                            size="lg"
                            autoFocus
                            required
                            autoComplete="off"
                            onChange={this.handleInputChange}
                        />
                    </Col>
                </FormGroup>
                <Row className="mt-4 d-flex align-items-center">
                    <Col xs="auto">
                        <Button
                            color="link"
                            onClick={this.resend}
                            className="border-0 p-0"
                        >
                            <FontAwesomeIcon icon={faRedo} className="mr-1" />
                            {I18n.get('Resend Code')}
                        </Button>
                        <br />
                        {onModalToggle ? (
                            <Button
                                color="link"
                                onClick={() => this.changeState('signIn')}
                                className="border-0 p-0"
                            >
                                <SignInLinkContents />
                            </Button>
                        ) : (
                            <Link
                                to="/sign-in"
                                onClick={() => this.changeState('signIn')}
                            >
                                <SignInLinkContents />
                            </Link>
                        )}
                    </Col>
                    <Col className="text-right">
                        <Button type="submit" color="primary" size="lg">
                            {I18n.get('Confirm')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}
