import gql from 'graphql-tag';
import {
    projectFile,
    workItemCategory,
    message,
    userWithoutContact,
    s3Object
} from './fragments';

export const createProject = gql`
    mutation CreateProject($input: CreateProjectInput!) {
        createProject(input: $input) {
            id
            name
            owner {
                id
                givenName
                familyName
                username
            }
            ownerStr
            primaryType
            secondaryType
            stage
            estimatedStart
            location {
                locality
                region
                country
            }
            workItems {
                categories {
                    ...WorkItemCategoryFragment
                }
                scope
            }
            categoryStr
            files {
                ...ProjectFileFragment
            }
            views
            status
        }
    }
    ${projectFile}
    ${workItemCategory}
`;

export const createBid = gql`
    mutation CreateBid($input: CreateBidInput!) {
        createBid(input: $input) {
            id
            events {
                timestamp
                status
            }
            bidProjectId
            bidConversationId
            bidUserBidderId
        }
    }
`;

export const createConvo = gql`
    mutation CreateConvo($input: CreateConversationInput!) {
        createConvo(input: $input) {
            id
            name
            createdAt
            associated {
                items {
                    convoLinkUserId
                    user {
                        id
                        givenName
                        familyName
                    }
                }
            }
        }
    }
`;

export const createMessage = gql`
    mutation CreateMessage($input: CreateMessageInput!) {
        createMessage(input: $input) {
            ...MessageFragment
        }
    }
    ${message}
`;

export const registerUserWithEmail = gql`
    mutation RegisterUser($input: CreateUserInput!) {
        registerUser(input: $input) {
            ...UserWithoutContact
            email
        }
    }
    ${userWithoutContact}
`;

export const registerUserWithPhoneNumber = gql`
    mutation RegisterUser($input: CreateUserInput) {
        registerUser(input: $input) {
            ...UserWithoutContact
        }
    }
    ${userWithoutContact}
`;

export const updateUser = gql`
    mutation updateUser(
        $id: ID!
        $email: String
        $preferredUsername: String
        $role: String
        $biography: String
        $location: String
        $facebook: String
        $twitter: String
        $linkedIn: String
        $companySite: String
        $personalSite: String
        $gravatar: String
        $avatar: S3ObjectInput
        $isGravatar: Boolean
    ) {
        updateUser(
            input: {
                id: $id
                email: $email
                preferredUsername: $preferredUsername
                role: $role
                biography: $biography
                location: $location
                facebook: $facebook
                twitter: $twitter
                linkedIn: $linkedIn
                companySite: $companySite
                personalSite: $personalSite
                gravatar: $gravatar
                isGravatar: $isGravatar
                avatar: $avatar
            }
        ) {
            id
            email
            preferredUsername
            role
            biography
            location
            facebook
            twitter
            linkedIn
            companySite
            personalSite
            gravatar
            isGravatar
            avatar {
                ...S3ObjectFragment
            }
        }
    }
    ${s3Object}
`;

export const createConvoLink = gql`
    mutation createConvoLink($userId: ID!, $convoId: ID!, $name: String!) {
        createConvoLink(
            input: {
                convoLinkUserId: $userId
                convoLinkConversationId: $convoId
                name: $name
                status: "CREATING"
            }
        ) {
            id
            name
            status
            conversation {
                id
                name
                createdAt
                associated {
                    items {
                        convoLinkUserId
                        user {
                            id
                            givenName
                            familyName
                        }
                    }
                }
            }
        }
    }
`;

export const updateProject = gql`
    mutation updateProject($input: UpdateProjectInput!) {
        updateProject(input: $input) {
            estimatedStart
            files {
                ...ProjectFileFragment
            }
            id
            name
            primaryType
            secondaryType
            stage
        }
    }
`;

export const deleteProject = gql`
    mutation deleteProject($input: DeleteProjectInput!) {
        deleteProject(input: $input) {
            id
        }
    }
`;

export const updateConvoLink = gql`
    mutation updateConvoLink($id: ID!) {
        updateConvoLink(input: { id: $id, status: "READY" }) {
            id
            name
            convoLinkUserId
            status
            conversation {
                id
                name
                createdAt
                associated {
                    items {
                        convoLinkUserId
                        user {
                            id
                            givenName
                            familyName
                        }
                    }
                }
            }
        }
    }
`;
