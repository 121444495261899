// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:f42246f3-8b2e-4c9c-84ca-c1c6ff853cab",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_zbh74WqZz",
    "aws_user_pools_web_client_id": "6bhj89dgq7ea0baiafosthga0u",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_mobile_analytics_app_id": "ea0db551b112413fa87ee14e132d988d",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://dsohy37myzekjhm7sbrfa6c7te.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "pallet-user-content-prod",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
