import React from 'react';
import classNames from 'classnames';
import { Auth, I18n, JS, Logger } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmilePlus, faKey } from '@fortawesome/pro-solid-svg-icons';
import AuthPiece from '../AuthPiece';
// import { FederatedButtons } from '../FederatedSignIn';
// import styles from './SignIn.module.scss';

const logger = new Logger('SignIn');

const JoinLinkContents = () => (
    <>
        <FontAwesomeIcon icon={faSmilePlus} className="mr-1" />
        {I18n.get('Join Pallet')}
    </>
);

const ForgotLinkContents = () => (
    <>
        <FontAwesomeIcon icon={faKey} className="mr-2" />
        {I18n.get('Forgot your password?')}
    </>
);
class SignIn extends AuthPiece {
    constructor(props) {
        super(props);

        this.checkContact = this.checkContact.bind(this);
        this.signIn = this.signIn.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        this.validAuthStates = [
            'signIn',
            'signedIn', // TEMP
            'signedOut', // TODO: Check if this is redundant
            'signedUp' // TEMP
        ];
        this.state = {
            loading: false
        };
    }

    componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown(event) {
        if (event.keyCode !== 13) return;
        if (this.props.authState === 'signIn') this.signIn();
    }

    async checkContact(user) {
        const data = await Auth.verifiedContact(user);
        if (!JS.isEmpty(data.verified)) this.changeState('signedIn', user);
        // router.push(router.location.state.target)
        else this.changeState('verifyContact', { user, data });
    }

    async signIn(event) {
        if (event) event.preventDefault();
        const { username, password } = this.inputs;
        this.setState({ loading: true });
        try {
            const user = await Auth.signIn(username, password);
            const { challengeName, challengeParam } = user;
            switch (challengeName) {
                case 'SMS_MFA':
                case 'SOFTWARE_TOKEN_MFA':
                    logger.debug(`confirm user with ${challengeName}`);
                    this.changeState('confirmSignIn', user);
                    break;
                case 'NEW_PASSWORD_REQUIRED':
                    logger.debug('require new password', challengeParam);
                    this.changeState('requireNewPassword', user);
                    break;
                case 'MFA_SETUP':
                    logger.debug('TOTP setup', challengeParam);
                    this.changeState('TOTPSetup', user);
                    break;
                default:
                    await this.checkContact(user);
            }
        } catch (err) {
            switch (err.code) {
                case 'UserNotConfirmedException':
                    logger.debug('the user is not confirmed');
                    this.changeState('confirmSignUp', { username });
                    break;
                case 'PasswordResetRequiredException':
                    logger.debug('the user requires a new password');
                    this.changeState('forgotPassword', { username });
                    break;
                default:
                    this.error(err);
            }
        } finally {
            this.setState({ loading: false });
            // TODO: Remove!
            window.location.reload();
        }
    }

    showComponent() {
        const {
            // authState,
            // federated,
            // onStateChange,
            onModalToggle
        } = this.props;

        return (
            <Form onSubmit={this.signIn}>
                {/* <FederatedButtons
                    federated={federated}
                    authState={authState}
                    onStateChange={onStateChange}
                />
                <div
                    className={classNames(
                        styles.labelBreak,
                        'mb-3',
                        'd-block',
                        'text-center',
                        'text-muted',
                        'font-italic',
                        'font-weight-light'
                    )}
                >
                    <span
                        className={classNames(
                            'd-inline-block',
                            'position-relative'
                        )}
                    >
                        {I18n.get('or')}
                    </span>
                </div> */}
                <FormGroup row>
                    <Label for="username" xs={3}>
                        {I18n.get('User')}
                    </Label>
                    <Col xs={9}>
                        <Input
                            type="text"
                            name="username"
                            id="username"
                            placeholder={I18n.get('Email or Phone')}
                            autoFocus
                            required
                            onChange={this.handleInputChange}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="password" xs={3}>
                        {I18n.get('Password')}
                    </Label>
                    <Col xs={9}>
                        <Input
                            type="password"
                            name="password"
                            id="password"
                            required
                            onChange={this.handleInputChange}
                        />
                    </Col>
                </FormGroup>
                <Row
                    className={classNames(
                        'mt-4',
                        'd-flex',
                        'align-items-center'
                    )}
                >
                    <Col xs="auto">
                        {onModalToggle ? (
                            <>
                                <Button
                                    color="link"
                                    onClick={() => this.changeState('signUp')}
                                    className="border-0 p-0"
                                >
                                    <JoinLinkContents />
                                </Button>
                                <br />
                                <Button
                                    color="link"
                                    onClick={() =>
                                        this.changeState('forgotPassword')
                                    }
                                    className="border-0 p-0"
                                >
                                    <ForgotLinkContents />
                                </Button>
                            </>
                        ) : (
                            <>
                                <Link
                                    to="/join"
                                    onClick={() => this.changeState('signUp')}
                                >
                                    <JoinLinkContents />
                                </Link>
                                <br />
                                <Link
                                    to="/reset-password"
                                    onClick={() =>
                                        this.changeState('forgotPassword')
                                    }
                                >
                                    <ForgotLinkContents />
                                </Link>
                            </>
                        )}
                    </Col>
                    <Col className="text-right">
                        <Button
                            type="submit"
                            color="primary"
                            size="lg"
                            disabled={this.state.loading}
                        >
                            {I18n.get('Sign In')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default SignIn;
