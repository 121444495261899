import React, { useState, useEffect } from 'react';
import { string, shape, func } from 'prop-types';
import classNames from 'classnames';
import { I18n } from 'aws-amplify';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { NavLink as Link } from 'react-router-dom';
// import slugify from 'slugify';
import {
    Container,
    Collapse,
    Navbar as BSNavbar,
    NavbarBrand,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faComment,
    faLayerPlus,
    faBell,
    faUser,
    // faBuilding,
    faLayerGroup,
    faAddressCard,
    faCog,
    faSignOut
} from '@fortawesome/pro-solid-svg-icons';
import Logo from '../Logo';
// import Search from './Search';
import { showAuthModal as _showAuthModal } from '../../state/modules/appearance/modal';
import withUser from '../withUser';
import Avatar from '../Avatar';
import styles from './Navbar.module.scss';

const linkClass = classNames(
    styles.link,
    'd-flex',
    'd-lg-block',
    'align-items-center'
);
const iconClass = classNames(
    styles.icon,
    'mr-2',
    'mx-lg-auto',
    'mb-lg-1',
    'd-lg-block'
);

const Navbar = ({ pathname, user, signOut, showAuthModal }) => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        setMenuOpen(false);
    }, [pathname]);

    const toggleNavMenu = () => setMenuOpen(!isMenuOpen);
    const handleSignIn = event => showAuthModal('signIn', event);
    const handleJoin = event => showAuthModal('signUp', event);
    const handleUserLink = !user ? handleSignIn : () => {};

    // NOTE: This breaks causing whole app to crash, need a need a better way to validate the
    // possibility of null preferredUsernames
    // const {preferredUsername} = user
    // const sluggedUsername = slugify(preferredUsername, {
    //     replacement: '-',
    //     remove: /[*+~.()'"!:@]/g,
    //     lower: true
    // });

    return (
        <BSNavbar
            light
            expand="lg"
            className={classNames(styles.root, 'sticky-top')}
            id="global-nav"
        >
            <Container>
                <NavbarBrand
                    tag={Link}
                    to="/"
                    className={classNames('d-flex', 'align-items-center')}
                >
                    <Logo height="40px" />
                    <h4
                        className={classNames(
                            'd-inline',
                            'm-0',
                            'ml-2',
                            'text-primary'
                        )}
                    >
                        {I18n.get('Pallet')}
                    </h4>
                </NavbarBrand>
                <NavbarToggler onClick={toggleNavMenu} />
                <Collapse isOpen={isMenuOpen} navbar>
                    {/* <Search /> */}
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <NavLink
                                tag={Link}
                                to="/submit"
                                activeClassName={styles.active}
                                className={linkClass}
                                onClick={handleUserLink}
                            >
                                <FontAwesomeIcon
                                    icon={faLayerPlus}
                                    fixedWidth
                                    className={iconClass}
                                />
                                Submit
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={Link}
                                to="/messages"
                                activeClassName={styles.active}
                                className={linkClass}
                                onClick={handleUserLink}
                            >
                                <FontAwesomeIcon
                                    icon={faComment}
                                    fixedWidth
                                    className={iconClass}
                                />
                                Messages
                            </NavLink>
                        </NavItem>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle
                                nav
                                caret
                                className={linkClass}
                                onClick={handleUserLink}
                            >
                                <FontAwesomeIcon
                                    icon={faBell}
                                    fixedWidth
                                    className={iconClass}
                                />
                                Alerts
                            </DropdownToggle>
                            {user && (
                                <DropdownMenu right>
                                    <DropdownItem
                                        disabled
                                        className={classNames(
                                            'text-center',
                                            'px-5',
                                            'py-3'
                                        )}
                                    >
                                        No Alerts
                                    </DropdownItem>
                                </DropdownMenu>
                            )}
                        </UncontrolledDropdown>
                        {user ? (
                            <NavItem>
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        nav
                                        caret
                                        className={linkClass}
                                    >
                                        <Avatar
                                            className={classNames(
                                                'mr-2',
                                                'mx-lg-auto',
                                                'd-lg-block'
                                            )}
                                        />
                                        {user.givenName}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem
                                            tag={Link}
                                            to={`/${user.preferredUsername}`}
                                            activeClassName="active"
                                        >
                                            <FontAwesomeIcon
                                                icon={faUser}
                                                fixedWidth
                                                className="mr-2"
                                            />
                                            Account
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        {/* user.companies.length !== 0 && (
                                                <DropdownItem
                                                    tag={Link}
                                                    to="/me/companies"
                                                    activeClassName="active"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faBuilding}
                                                        fixedWidth
                                                        className="mr-2"
                                                    />
                                                    Companies
                                                </DropdownItem>
                                            ) */}
                                        <DropdownItem
                                            tag={Link}
                                            to={`/${user.preferredUsername}/projects`}
                                            activeClassName="active"
                                        >
                                            <FontAwesomeIcon
                                                icon={faLayerGroup}
                                                fixedWidth
                                                className="mr-2"
                                            />
                                            Projects
                                        </DropdownItem>
                                        <DropdownItem
                                            tag={Link}
                                            to={`/${user.preferredUsername}/contacts`}
                                            activeClassName="active"
                                        >
                                            <FontAwesomeIcon
                                                icon={faAddressCard}
                                                fixedWidth
                                                className="mr-2"
                                            />
                                            Contacts
                                        </DropdownItem>
                                        <DropdownItem
                                            tag={Link}
                                            to={`/${user.preferredUsername}/settings`}
                                            activeClassName="active"
                                        >
                                            <FontAwesomeIcon
                                                icon={faCog}
                                                fixedWidth
                                                className="mr-2"
                                            />
                                            Settings
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem
                                            onClick={() => {
                                                signOut();
                                                // TODO: Remove!
                                                window.location.reload();
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faSignOut}
                                                fixedWidth
                                                className="mr-2"
                                            />
                                            Sign Out
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </NavItem>
                        ) : (
                            <NavItem
                                className={classNames(
                                    'd-flex',
                                    'align-items-center',
                                    'my-2',
                                    'my-lg-0',
                                    'ml-lg-2'
                                )}
                            >
                                <Button
                                    tag={Link}
                                    to="/sign-in"
                                    color="secondary"
                                    size="sm"
                                    className="mr-2"
                                    onClick={handleSignIn}
                                >
                                    Sign In
                                </Button>
                                <Button
                                    tag={Link}
                                    to="/join"
                                    color="primary"
                                    size="sm"
                                    onClick={handleJoin}
                                >
                                    Join
                                </Button>
                            </NavItem>
                        )}
                    </Nav>
                </Collapse>
            </Container>
        </BSNavbar>
    );
};

Navbar.propTypes = {
    pathname: string.isRequired,
    user: shape({}),
    signOut: func.isRequired,
    showAuthModal: func.isRequired
};

Navbar.defaultProps = {
    user: null
};

const mapStateToProps = ({
    router: {
        location: { pathname }
    }
}) => ({
    pathname
});

export default compose(
    withUser,
    connect(mapStateToProps, { showAuthModal: _showAuthModal })
)(Navbar);
