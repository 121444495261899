import React, { useCallback, useEffect } from 'react';
import { func, shape, string, bool } from 'prop-types';
import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks';
import { compose } from 'redux';
import { Auth, Logger } from 'aws-amplify';
import withSession from '../withSession';
import { getUser } from '../../graphql/queries';
import {
    registerUserWithEmail as _registerUserWithEmail,
    registerUserWithPhoneNumber as _registerUserWithPhoneNumber,
    updateUser as _updateUser
} from '../../graphql/mutations';
import withGoogle from '../Authenticator/Provider/withGoogle';
import withFacebook from '../Authenticator/Provider/withFacebook';
import Constants from '../Authenticator/common/constants';

const logger = new Logger('withUser');
const AUTHENTICATOR_AUTHSTATE = 'amplify-authenticator-authState';

// withUser High-Order Component: provides AppSync/DynamoDB user from Cognito session
const withUser = WrappedComponent => {
    const UserComponent = ({
        session,
        loading: sessionLoading,
        // error: sessionError,
        // We destructure some of these props just so we don't pass them along to the DOM
        onStateChange,
        googleSignIn,
        googleSignOut,
        facebookSignIn,
        facebookSignOut,
        ga,
        fb,
        facebookAppId,
        ...props
    }) => {
        // Extract Cognito user attributes from session
        const {
            idToken: {
                payload: {
                    sub: id,
                    given_name: givenName,
                    family_name: familyName,
                    email,
                    role,
                    biography,
                    location,
                    phone_number: phoneNumber,
                    preferred_username: preferredUsername,
                    facebook,
                    twitter,
                    linkedIn,
                    companySite,
                    personalSite,
                    avatar,
                    isGravatar,
                    gravatar
                } = {}
            } = {}
        } = session || {};
        const cognitoUser = {
            id,
            givenName,
            familyName,
            email,
            role,
            biography,
            location,
            phoneNumber,
            preferredUsername,
            subscribedToNewsletter: false,
            avatar,
            isGravatar,
            gravatar
        };

        // Query for user in Dynamo DB
        const {
            loading: userLoading,
            data: { getUser: user } = {},
            error: userQueryError
            // refetch: refreshUser
        } = useQuery(getUser, {
            skip: !id,
            variables: { id }
        });

        const loading = sessionLoading || userLoading;

        // Graph QL mutation options
        const registerUserMutationOptions = {
            variables: {
                input: {
                    ...cognitoUser,
                    registered: true
                }
            },
            optimisticResponse: {
                registerUser: {
                    ...cognitoUser,
                    registered: false,
                    __typename: 'User',
                    userConversations: {
                        __typename: 'ModelConvoLinkConnection',
                        items: []
                    }
                }
            },
            update: (proxy, { data: { registerUser: response } }) => {
                const QUERY = {
                    // Dynamic
                    query: getUser,
                    variables: { id }
                };
                const data = {
                    ...proxy.readQuery(QUERY),
                    getUser: { ...response }
                };

                proxy.writeQuery({ ...QUERY, data });
            }
        };

        const updateUserMutationOptions = {
            variables: {
                input: {
                    id,
                    email,
                    preferredUsername,
                    role,
                    biography,
                    location,
                    facebook,
                    twitter,
                    linkedIn,
                    companySite,
                    personalSite,
                    avatar,
                    isGravatar,
                    gravatar
                }
            },
            update: (proxy, { data: { updateUser: response } }) => {
                const QUERY = {
                    query: getUser,
                    variables: { id }
                };
                const data = {
                    ...proxy.readQuery(QUERY),
                    getUser: { ...response }
                };

                proxy.writeQuery({ ...QUERY, data });
            }
        };

        const [registerUserWithEmail] = useMutation(
            _registerUserWithEmail,
            registerUserMutationOptions
        );
        const [registerUserWithPhoneNumber] = useMutation(
            _registerUserWithPhoneNumber,
            registerUserMutationOptions
        );

        const registerUser = email
            ? registerUserWithEmail
            : registerUserWithPhoneNumber;

        const [updateUser] = useMutation(
            _updateUser,
            updateUserMutationOptions
        );

        // Get direct access to Apollo client to clear store
        const client = useApolloClient();

        const resetStore = useCallback(async () => {
            logger.debug('Clearing store');
            try {
                await client.resetStore();
            } catch (error) {
                logger.error(error);
            }
        }, [client]);

        const signOut = useCallback(async () => {
            let payload = {};
            try {
                localStorage.removeItem(AUTHENTICATOR_AUTHSTATE);
                payload =
                    JSON.parse(
                        localStorage.getItem(Constants.AUTH_SOURCE_KEY)
                    ) || {};
                localStorage.removeItem(Constants.AUTH_SOURCE_KEY);
            } catch (error) {
                logger.debug(
                    `Failed to parse the info from ${Constants.AUTH_SOURCE_KEY} from localStorage with ${error}`
                );
            }
            if (Object.keys(payload).length) {
                logger.debug('Sign out from ', payload.provider);
                switch (payload.provider) {
                    case Constants.GOOGLE:
                        if (googleSignOut) await googleSignOut();
                        break;
                    case Constants.FACEBOOK:
                        if (facebookSignOut) await facebookSignOut();
                        break;
                    default:
                        break;
                }
            }
            try {
                await Auth.signOut();
            } catch (error) {
                logger.error('Failed to sign out: ', error);
            }
            /*
             * In case the Amplify session has ended but a user still exists
             * in the Apollo store, we need to erase the user from the store.
             * TODO: More granular reset of only user data as opposed to whole store.
             * Also Amplify recommends client.resetStore() instead
             */
            await resetStore();
        }, [googleSignOut, facebookSignOut, resetStore]);

        // Main routine - syncs Cognito user with DynamoDB/AppSync user
        const sync = useCallback(async () => {
            try {
                logger.debug('Registering Cognito user with Dynamo DB');
                await registerUser();
            } catch (error) {
                logger.debug(error);
            }
        }, [registerUser]);

        useEffect(() => {
            let isMounted = true;
            let queried = false;

            if (isMounted && !queried && !loading && session) {
                if (userQueryError)
                    logger.error(
                        'Failed to query Dynamo DB user through Graph QL:',
                        userQueryError
                    );
                if (user) queried = true;
                else sync();
            }

            return () => {
                isMounted = false;
            };
        }, [userQueryError, loading, session, user, sync]);

        return (
            <WrappedComponent
                loading={Boolean(loading || (session && !user))}
                user={user}
                session={session}
                signOut={signOut}
                updateUser={updateUser}
                {...props}
                // refreshUser={refreshUser}
            />
        );
    };

    UserComponent.propTypes = {
        session: shape({}),
        loading: bool.isRequired,
        // error: shape({}),
        onStateChange: func,
        googleSignIn: func.isRequired,
        googleSignOut: func.isRequired,
        facebookSignIn: func.isRequired,
        facebookSignOut: func.isRequired,
        updateUser: func.isRequired,
        ga: shape({}),
        fb: shape({}),
        facebookAppId: string
    };

    UserComponent.defaultProps = {
        session: undefined,
        // error: undefined
        onStateChange: undefined,
        ga: undefined,
        fb: undefined,
        facebookAppId: undefined
    };

    return compose(withSession, withGoogle, withFacebook)(UserComponent);
};

export default withUser;
