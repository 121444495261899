import gql from 'graphql-tag';

export const s3Object = gql`
    fragment S3ObjectFragment on S3Object {
        bucket
        region
        key
    }
`;

export const leafProjectCategory = gql`
    fragment LeafProjectCategoryFragment on ProjectCategory {
        categoryId
        level
        name
        image {
            ...S3ObjectFragment
        }
    }
    ${s3Object}
`;

export const category = gql`
    fragment ProjectCategoryFragment on ProjectCategory {
        ...LeafProjectCategoryFragment
        subcategories {
            ...LeafProjectCategoryFragment
            subcategories {
                ...LeafProjectCategoryFragment
                subcategories {
                    ...LeafProjectCategoryFragment
                    subcategories {
                        ...LeafProjectCategoryFragment
                        subcategories {
                            ...LeafProjectCategoryFragment
                        }
                    }
                }
            }
        }
    }
    ${leafProjectCategory}
`;

export const projectFile = gql`
    fragment ProjectFileFragment on ProjectFile {
        type
        name
        isCover
        file {
            ...S3ObjectFragment
        }
    }
    ${s3Object}
`;

export const workItemCategory = gql`
    fragment WorkItemCategoryFragment on WorkItemCategory {
        categoryId
        level
        name
    }
`;

export const project = gql`
    fragment ProjectFragment on Project {
        id
        name
        owner {
            id
            givenName
            familyName
            username
        }
        bids {
            items {
                id
                events {
                    timestamp
                    status
                }
            }
            nextToken
        }
        primaryType
        secondaryType
        stage
        estimatedStart
        location {
            locality
            region
            country
        }
        workItems {
            categories {
                ...WorkItemCategoryFragment
            }
            scope
        }
        files {
            ...ProjectFileFragment
        }
        views
        status
    }
    ${workItemCategory}
    ${projectFile}
`;

export const message = gql`
    fragment MessageFragment on Message {
        id
        content
        createdAt
        owner
        isSent
        file {
            ...S3ObjectFragment
        }
        messageConversationId
        conversation {
            id
            name
            createdAt
        }
    }
    ${s3Object}
`;

export const userWithoutContact = gql`
    fragment UserWithoutContact on User {
        id
        givenName
        familyName
        subscribedToNewsletter
        registered
        preferredUsername
        role
        biography
        location
        companySite
        personalSite
        linkedIn
        facebook
        twitter
        avatar {
            ...S3ObjectFragment
        }
        gravatar
        userConversations {
            items {
                id
                name
                status
                conversation {
                    id
                    name
                    createdAt
                    associated {
                        items {
                            convoLinkUserId
                            user {
                                id
                                givenName
                                familyName
                            }
                        }
                    }
                }
            }
        }
    }
    ${s3Object}
`;

export default { category, message, userWithoutContact };
