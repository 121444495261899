import React from 'react';
import Helmet from 'react-helmet';
import { Switch, Route, Redirect } from 'react-router-dom';
import withAnalytics from '../withAnalytics';
import {
    Home,
    Project,
    ProjectsByCategories,
    User,
    SignIn,
    SignUp,
    ResetPassword,
    Submit,
    Messages,
    About,
    Legal,
    NotFound
} from '../../pages';
import ErrorBoundary from '../ErrorBoundary';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Modal from '../Modal';
import 'typeface-montserrat';
import './App.scss';

const joinPath = '/join';

const App = () => (
    <ErrorBoundary>
        <Helmet defaultTitle="Pallet" titleTemplate="Pallet » %s" />
        <Navbar className="NavBar" />
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/project/:id" component={Project} />
            <Route path="/categories" component={ProjectsByCategories} />
            {/*
                <Route path='/location/:id' component={Location} />
                <Route path='/company/:id' component={Company} />
            */}
            <Route path="/submit" component={Submit} />
            <Route exact path="/sign-in" component={SignIn} />
            <Route exact path={joinPath} component={SignUp} />
            <Redirect exact from="/sign-up" to={joinPath} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route path="/messages" component={Messages} />
            <Route exact path="/about" component={About} />
            <Route exact path="/(privacy|terms|faq)" component={Legal} />
            {/* User/Company pages must be at end of routes list due to dynamic path matching */}
            <Route path="/:id/:page?" component={User} />
            <Route component={NotFound} />
        </Switch>
        <Footer />
        <Modal />
    </ErrorBoundary>
);

export default withAnalytics(App);
